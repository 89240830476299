<script setup lang="ts">
const {$activeModalsBus} = useNuxtApp();
const showCarForm = ref(false);
$activeModalsBus.listen("openPopup", () => {
  showCarForm.value = true;
});
$activeModalsBus.listen("closePopup", () => {
  showCarForm.value = false;
});
</script>

<template>
  <v-dialog v-model="showCarForm" :max-width="900">
    <v-card>
      <div class="modal-body p-0">
        <div class="row">
          <div class="col-md-6">
            <div class="col-md-10 mx-auto py-4 px-4">
              <div class="title--sm text-center font-weight-bold"></div>
              <div class="subtitle-semibold text-center font-weight-bold">
                כדי שנוכל לחזור אליך במהירות ולעזור לך, נצטרך כמה פרטים.
              </div>
              <div class="form form-car form--black mt-4 mb-3">
                <div class="wpcf7 js" id="wpcf7-f8778-o2" lang="en-US" dir="ltr">
                  <FormCar/>
                </div>
              </div>
            </div>
          </div>
          <div
              class="col-md-6 cover"
              style="background-image: url('https://admin.sasa.co.il/wp-content/uploads/2020/12/red-car-pic.jpg')"
          ></div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss"></style>
